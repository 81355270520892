let loader = {};

loader.createObserver = function() {
    let observer;
    if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(function(items) {
            $.each(items, function(_index, item) {
                if (item.isIntersecting) {
                    item.target.setAttribute(
                        'src',
                        item.target.getAttribute('data-src')
                    );
                    observer.unobserve(item.target);
                }
            });
        });
    }
    return observer;
};

loader.lazyLoadImage = function($element, image) {
    let observer = loader.createObserver();
    if (observer) {
        if (image) {
            $element.attr('data-src', image);
        }
        observer.observe($element[0]);
    } else {
        if (image) {
            $element.attr('src', image);
        } else {
            $element.attr('src', $element.attr('data-src'));
        }
    }
};

loader.createGeneralIntersectionObserver = function(callback) {
    if ('IntersectionObserver' in window) {
        let generalObserver = new IntersectionObserver(function(items) {
            $.each(items, function(_index, item) {
                if (item.target.getAttribute('data-lazy-loaded') != 'true' && item.isIntersecting) {
                    callback();
                    item.target.setAttribute('data-lazy-loaded', 'true');
                    generalObserver.unobserve(item.target);
                }
            });
        });
        return generalObserver;
    }
};

export default loader;
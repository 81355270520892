/**
 * jQuery Lite Tooltip
 *
 * @version 0.0.1
 * @author Branko Sekulic
 */

(function($) {

    $.fn.liteTooltip = function(options) {

        options = options || {};

        var defaults = {
            hideClass: null,
            openClass: null,
            action: 'hover',
            delay: 0,
            onOpen: null
        };

        options = $.extend(defaults, options);

        var openTooltip = function($el, $open){

            if(options.hideClass){
                $el.removeClass(options.hideClass);
            }else{
                $el.show();
            }

            var openClass = $open.data('openclass') || options.openClass;

            if(openClass){
                $open.addClass(openClass);
            }

            if (options.onOpen && typeof options.onOpen === 'function') {
                options.onOpen();
            }
        };

        var hideTooltip = function($el, $open){

            if(options.hideClass){
                $el.addClass(options.hideClass);
            }else{
                $el.hide();
            }

            var openClass = $open.data('openclass') || options.openClass;

            if(openClass){
                $open.removeClass(openClass);
            }
        };

        var interval = null;

        if (options.delay) {
            var doOpen = openTooltip,
                doHide = hideTooltip;

            openTooltip = function ($el, $open) {
                if (interval) {
                    clearTimeout(interval);
                }

                interval = setTimeout(function() {
                    doOpen($el, $open);
                }, options.delay);
            }

            hideTooltip = function ($el, $open) {
                if (interval) {
                    clearTimeout(interval);
                }

                doHide($el, $open);
            }
        }

        var init = function(el) {


            if(!$(el).attr('id')){
                $(el).attr('id', 'j-popup-' + parseInt(Math.random() * 100000000, 10));
            }

            var id = $(el).attr('id'),
                open = id + '-open',
                opened = false,
                $el = $(el),
                $open = $('.' + open),
                timeoutId = null;

            if(options.action === 'hover'){

                $('.' + open).on('mouseover', function(e){
                    if (timeoutId) { // prevent multiple open/hide
                        clearTimeout(timeoutId);
                    }
                    openTooltip($el, $open);
                });

                $('.' + open).on('mouseout', function(e){
                    timeoutId = setTimeout(function() {
                        hideTooltip($el, $open);
                    }, 0);
                });

            }else if(options.action === 'click'){

                $('.' + open).on('click', function(e){
                    if(opened){
                        hideTooltip($el, $open);
                    }else{
                        openTooltip($el, $open);
                    }
                    opened = !opened;
                });

                $(document).click(function(e){

                    if (!$(e.target).parents().addBack().is('#' + id + ', .' + open)) {
                        hideTooltip($el, $open);
                        opened = false;
                    }
                });

                $(document).keyup(function(e){

                    if(e.keyCode === 27) {
                        hideTooltip($el, $open);
                        opened = false;   
                    }
                });
            }

            el.closeTooltip = function() {
                hideTooltip($el, $open);
                opened = false;
            };
        };

        this.each(function() {
            init(this);
        });
    };
})(jQuery);
export default function() {
    (function ($) {

        $.fn.tabs = function (opts) {

            let defaults = {
                    event: 'click',
                    activeClass: 'active',
                    hideClass: 'hide',
                    onChange: null,
                    activeTab: 0
                },
                options = $.extend({}, defaults, opts);


            let init = function (el) {

                if (options.activeTab) {
                    let tab = $($(el).find('[data-tab]')[options.activeTab - 1]).data('tab');
                    showHide(el, tab);
                }

                $(el).find('[data-tab]').on(options.event, function () {

                    let tab = $(this).data('tab');

                    showHide(el, tab);

                    if (options.onChange) {
                        options.onChange(this);
                    }

                });
            };

            let showHide = function (el, tab) {
                $(el).find('[data-tab]').removeClass(options.activeClass);
                $(el).find('[data-tab=' + tab + ']').addClass(options.activeClass);

                $(el).find('[data-tab-content]').addClass(options.hideClass);
                $(el).find('[data-tab-content=' + tab + ']').removeClass(options.hideClass);
            };

            this.each(
                function () {
                    init(this);
                }
            );

        };

    })(jQuery);
}
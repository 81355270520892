import '../../vendor/jquery.popupoverlay.js';
import global from '../modules/global';

let utils = {};

/**
 * Get number in proper format
 * @param  {number} number
 * @param  {integer} decimals
 * @param  {boolean} removeFirstZero will return .xxx for 0.xxx
 * @return {string}
 */
utils.numberFormat = function (number, decimals, removeFirstZero) {
    if (!number) {
        return 0;
    }

    decimals = decimals || 0;
    removeFirstZero = removeFirstZero || false;

    let x, x1, x2, out;
    number = '' + number.toFixed(decimals);
    x = number.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';

    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    out = x1 + x2;

    if (removeFirstZero) {
        let firstChar = out.slice(0, 1);
        if (firstChar == 0) {
            out = out.slice(1, out.length);
        }
    }

    return out;

};

/**
 * [ucFirst description]
 * @param  {[type]} str [description]
 * @return {[type]}     [description]
 */
utils.ucFirst = function (str) {

    if (str && str.length) {
        str = str[0].toUpperCase() + str.slice(1).toLowerCase();
    }

    return str;
};

/**
 * Returns only numeric values from string and .
 * @TODO add proper format validation
 * @param  {[type]} val [description]
 * @return {[type]}     [description]
 */
utils.numericOnly = function (val) {

    return val.toString().replace(/[^0-9|.]/g, '');
};

/**
 * [validTerm description]
 * @param  {[type]} val [description]
 * @return {[type]}     [description]
 */
utils.validTerm = function (val) {

    if (typeof val === 'string' && val) {

        val = val.replace(/^\s+|\s+$/g, '');
        if (val.length <= 2) {
            return false;
        }

        let regex = /([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+/;
        return !regex.test(val);
    }

    return false;
};


/**
 * convert price to some currency and do formatting
 * @param userCode
 * @param code
 * @param baseprice
 * @param baseCurrency
 * @param currencies
 * @param applyHtmlCode
 * @param applyNumberFormat
 * @returns {string}
 */
utils.getPrice = function (userCode, code, baseprice, baseCurrency, currencies, applyHtmlCode, applyNumberFormat) {
    let htmlCode,
        ret = '',
        convert = true;

    if (typeof applyHtmlCode === 'undefined') {
        applyHtmlCode = true;
    }

    if (typeof applyNumberFormat === 'undefined') {
        applyNumberFormat = true;
    }

    if (userCode && userCode.toLowerCase() !== 'default') {
        code = userCode;
    }

    if (baseCurrency === code) { // don't convert if price is already in proper currency
        convert = false;
    }

    if (convert && code && code != 'default' && currencies[code] && currencies[code].rate &&
        currencies[baseCurrency] && currencies[baseCurrency].rate) {

        let newRate = currencies[code].rate.rate,
            baseRate = currencies[baseCurrency].rate.rate,
            price = utils.numericOnly(baseprice);

        htmlCode = currencies[code] ? currencies[code].htmlCode : '';

        if (price > 0) {
            ret = parseFloat(price / baseRate * newRate);
            if (applyNumberFormat) {
                ret = utils.numberFormat(ret, 4);
            }
        } else {
            ret = parseFloat(baseprice);
            if (applyNumberFormat) {
                ret = utils.numberFormat(ret, 4);
            }
        }
    } else {
        htmlCode = currencies[baseCurrency] ? currencies[baseCurrency].htmlCode : '';

        ret = parseFloat(baseprice);

        if (applyNumberFormat) {
            ret = utils.numberFormat(ret, 4);
        }
    }

    if (applyHtmlCode) {
        ret = htmlCode + ret;
    }

    return ret;
};


/**
 * [decode description]
 * @param  {[type]} str [description]
 * @return {[type]}     [description]
 */
utils.htmlspecialcharsDecode = function (str) {
    return String(str)
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"');
};

/**
 * we need this for tracking for internal urls
 * @param  {[type]} host [description]
 * @param  {[type]} path [description]
 * @param  {[type]} protocol [description]
 * @return {[type]}      [description]
 */
utils.createAbsoluteUrl = function (host, path, protocol) {
    protocol = protocol || 'http';
    return protocol + '://' + host + '/' + path;
};


/**
 * split string, add more... and wrap rest in span
 * @param string
 */
utils.splitString = function (str, len, minWrap) {

    len = len || 100;
    minWrap = minWrap || 0;

    let escaper = utils.createEscaper(true),
        str1 = str.substring(0, len),
        str2 = str.substring(len, str.length);

    str1 = escaper(str1);
    str2 = escaper(str2);

    if (str2.length > minWrap) {
        str2 = '<span class="j-split-more-text" style="display: none">' + str2 + '</span><span><span class="j-split-more-dot">...</span> <a href="#" class="j-split-more-link">more</a></span>';
    }

    return {
        value: str1 + str2,
        more: str2.length
    };
};


/**
 * Makes URLs SEO friendly
 * @param  {string}     value
 * @return {string}
 */
utils.slugify = function (value) {
    if (typeof value === 'number') {
        return value;
    }

    let copy = '',
        result;

    copy += value;

    result = value
    // 1) convert to lowercase
        .toLowerCase()
        // 2) convert whitespace to dash
        .replace(/\s/g, '-')
        // 3) remove everything but alphanumeric characters and dashes
        .replace(/[^a-z0-9-]/g, '')
        // 4) remove consecutive dashes
        .replace(/-{2,}/g, '-')
        // 5) remove dash prefix (if present)
        .replace(/^-*/, '')
        // 6) remove dash suffix (if present)
        .replace(/-*$/, '');

    // added fail-safe in case symbol characters used in names (like 汉语)
    return result || encodeURIComponent(copy);
};

/**
 * Check if the value is number
 * @param  {[type]}  n [description]
 * @return {Boolean}   [description]
 */
utils.isNumber = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

/**
 * [numberNoExponent description]
 * @param  {[type]} num [description]
 * @return {[type]}     [description]
 */
utils.numberToDecimal = function (num) {

    let data = String(num).split(/[eE]/);
    if (data.length == 1) return data[0];

    let z = '', sign = num < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;

    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
};

/**
 * @param  {string}
 * @param  {[type]}
 * @return {object}
 */
utils.parseJson = function (json, defaultVal) {

    let parsed;

    try {
        parsed = JSON.parse(json);
    } catch (e) {
        parsed = defaultVal || null;
    }

    return parsed;
};

/**
 * to fixed number of decimals depends on
 * how big number is
 * @param  {number} num
 * @param  {number} min [min number of decimals]
 * @param  {number} max [max number of decimals]
 * @return {number}
 */
utils.toFixed = function (num, min, max) {
    if (num == 0) {
        return 0;
    }
    
    if (!num) {
        return NaN;
    }

    min = min || 0;
    max = max || 4;

    let decimals = num.toString().split('.').length > 1 ? num.toString().split('.')[1].length : 0;
    decimals = decimals < min ? min : decimals;
    decimals = decimals > max ? max : decimals;

    return parseFloat(num).toFixed(decimals);
};

/**
 * Makes a string plural or singular.
 *
 * @param number
 * @param string
 * @returns {string}
 */

utils.pluralize = function (number, string, plural) {

    let result = string;

    if (number > 1 || number < -1 || number == 0) {

        if (typeof plural !== 'undefined' && plural) {
            result = plural;
        } else {
            result = string + 's';
        }
    }

    return result;
};

/**
 * [translate description]
 * @param  {[type]} string [description]
 * @return {[type]}        [description]
 */
utils.translate = function (string) {
    global();

    let translates = window.FC.translate || {};

    return translates[string] || string;
};

/**
 * [translatePlural description]
 * @param  {[type]} number [description]
 * @param  {[type]} string [description]
 * @param  {[type]} plural [description]
 * @return {[type]}        [description]
 */
utils.translatePlural = function (number, string, plural) {

    string = utils.translate(string);
    plural = utils.translate(plural);

    return utils.pluralize(number, string, plural);
};

/**
 * Creates html escaper/unescaper.
 *
 * @param direction bool If true then escaper context is loaded, otherwise unescape context.
 * @return function Escaper/unescaper function.
 */
utils.createEscaper = function (direction) {
    if (typeof direction === 'undefined') {
        direction = true;
    }

    const escapeMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '\'': '&#x27;',
        '`': '&#x60;'
    };

    const unescapeMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#x27;': '\'',
        '&#x60;': '`'
    };

    let map = direction ? escapeMap : unescapeMap;

    let escaper = function (match) {
        return map[match];
    };

    let keys = [];

    for (let i in map) {
        if (!map.hasOwnProperty(i)) {
            continue;
        }

        keys.push(i);
    }

    let source = '(?:' + keys.join('|') + ')';

    let testRegexp = RegExp(source);
    let replaceRegexp = RegExp(source, 'g');

    return function (string) {
        string = string == null ? '' : '' + string;
        return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
    };
};

/**
 * [safeString description]
 * @param  {[type]} str [description]
 * @return {[type]}     [description]
 */
utils.safeString = function (str) {
    if (typeof str === 'undefined') return;

    let UNSAFE_CHARS_PATTERN = /[<>\/\u2028\u2029]/g;

    const UNICODE_CHARS = {
        '<': '\\u003C',
        '>': '\\u003E',
        '/': '\\u002F',
        '\u2028': '\\u2028',
        '\u2029': '\\u2029'
    };

    return str.replace(UNSAFE_CHARS_PATTERN, function (char) {
        return UNICODE_CHARS[char];
    });
};

/**
 * [preserveWhiteSpace description]
 * @param  {[type]} string [description]
 * @return {[type]}        [description]
 */
utils.preserveWhiteSpace = function (string) {

    const map = {
        ' ': '&nbsp;',
        '\t': '&nbsp;&nbsp;&nbsp;&nbsp;',
        '\n': '<br/>'
    };

    let escaper = function (match) {
        return map[match];
    };

    let keys = Object.keys(map),
        source = '(?:' + keys.join('|') + ')';

    let testRegexp = RegExp(source);
    let replaceRegexp = RegExp(source, 'g');


    string = string === null ? '' : '' + string;
    return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
};

utils.debounce = function (func, threshold, execAsap) {
    let timeout;

    return function debounced() {
        let obj = this, args = arguments;

        function delayed() {
            if (!execAsap)
                func.apply(obj, args);
            timeout = null;
        }

        if (timeout)
            clearTimeout(timeout);
        else if (execAsap)
            func.apply(obj, args);

        timeout = setTimeout(delayed, threshold || 100);
    };
};

/**
 * Remove non alphabet chars from tag className
 * @param  {String} Class
 * @return {String}
 */
utils.toClassName = function (klass) {

    return klass ? klass.replace(/ /g, '').replace(/[^a-zA-Z]+/g, '').toLowerCase() : '';
};

/**
 * [autocompleteHighlight description]
 * @param  {[type]} value [description]
 * @param  {[type]} term  [description]
 * @param  {[type]} data  [description]
 * @return {[type]}       [description]
 */
utils.autocompleteHighlight = function (value, term, data) {

    let Class = data[value] || '',
        categoryIcon = Class ? '<span title="' + Class + '" class="category-icon j-home-category-icon  icon-' + utils.toClassName(Class) + '"></span> ' : '';

    return categoryIcon + value.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + term.replace(/([\^\$\(\)\[\]\{\}\*\.\+\?\|\\])/gi, '\\$1') + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<strong>$1</strong>');
};

/**
 * [formatDate description]
 * @param  {[type]} date [description]
 * @return {[type]}      [description]
 */
utils.formatDate = function (date, locale, timezone, options) {
    try {
        locale = locale ? locale : 'default';
        options = options ? options : {};
        if (timezone) {
            options.timeZone = timezone;
        }
        if (typeof timezone !== 'undefined' && !timezone) {
            options.timeZoneName = 'short';
        }
        return date.toLocaleDateString(locale, options);
    } catch (e) {
        return '';
    }
};

/**
 * [formatDatetime description]
 * @param  {[type]} date [description]
 * @return {[type]}      [description]
 */
utils.formatDatetime = function (date, locale, timezone) {
    try {
        locale = locale ? locale : 'default';
        let options = timezone ? { timeZone: timezone } : {};
        if (typeof timezone !== 'undefined' && !timezone) {
            options.timeZoneName = 'short';
        }
        return date.toLocaleString(locale, options);
    } catch (e) {
        return '';
    }
};

utils.formatHumanDate = function(date, locale, timezone) {
    try {
        locale = locale ? locale : 'default';
        let options = timezone ? { timeZone: timezone } : {};
        let output = date.toLocaleDateString(locale, options) +' at ';
        if (typeof timezone !== 'undefined' && !timezone) {
            options.timeZoneName = 'short';
        }
        return output + date.toLocaleTimeString(locale, options);
    } catch (e) {
        return '';
    }
};

/**
 * match urls regex
 * @type {RegExp}
 */
utils.allUrls = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

/**
 * export as module if supported
 */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = utils;
}

/**
 * return array of colors of certain length
 *
 * it will make gradient between given colors to reach certain length
 *
 * @example gradientor(['#000000', '#0099ff', '#ffffff'], 10)
 * @param colors
 * @param steps
 * @returns {[*]}
 */
utils.gradientor = function (colors, steps) {

    let colorsLen = colors.length;

    let getR = function (color) {
        return parseInt(color.substring(1, 3), 16);
    };

    let getG = function (color) {
        return parseInt(color.substring(3, 5), 16);
    };

    let getB = function (color) {
        return parseInt(color.substring(5, 7), 16);
    };

    let gradient = function (start, end, length) {
        let sr, sg, sb,
            r = sr = getR(start),
            g = sg = getG(start),
            b = sb = getB(start),
            er = getR(end),
            eg = getG(end),
            eb = getB(end),
            gradientColor = [start];

        for (let i = 0; i < length - 1; i++) {

            r -= parseInt((sr - er) / length);
            g -= parseInt((sg - eg) / length);
            b -= parseInt((sb - eb) / length);

            if (i > 0 || length == 1) {
                gradientColor.push('#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1));
            }

        }

        return gradientColor;
    };

    let substeps = Math.floor(steps / (colorsLen - 1)) + 1,
        ret = [];

    for (let i = 0; i < colorsLen - 1; i++) {
        if (i === colorsLen - 2) {
            substeps = steps - ret.length;
        }
        let gradientColors = gradient(colors[i], colors[i + 1], substeps);
        ret = ret.concat(gradientColors);
    }

    ret.push(colors[colorsLen - 1]);

    return ret;

};

/**
 * Add/replace/remove query param value
 * query param will be removed if val === null
 * @param url
 * @param key
 * @param val
 * @returns search string
 */
utils.queryParam = function (url, key, val) {
    let search = url.split('?'),
        params = [],
        q = {},
        newParams = [];

    if (search[1]) {
        params = search[1].split('&');
    }

    for (let item of params) {
        let kv = item.split('=');
        q[kv[0]] = decodeURIComponent(kv[1]);
    }

    if (val === null) {
        delete q[key];
    } else {
        q[key] = val;
    }

    for (let p in q) {
        newParams.push(encodeURIComponent(p) + '=' + encodeURIComponent(q[p]));
    }

    let query = newParams.join('&');

    return query.length ? '?' + query : search[0];

};


/**
 * Max 5 decimals if price is low
 * @param  {[type]} price [description]
 * @return {[type]}       [description]
 */
utils.formatPrice = function (price) {

    if (!price) {
        return price;
    }

    return parseFloat(price).toFixed(Math.max(0, 5 - parseFloat(price).toFixed(0).length));
};

/**
 * helper for array uniq
 * e.g. a.filter( onlyUnique );
 * @param  {[type]} value [description]
 * @param  {[type]} index [description]
 * @param  {[type]} self  [description]
 * @return {[type]}       [description]
 */
utils.onlyUnique = function (value, index, self) {
    return self.indexOf(value) === index;
};

utils.capitalizeFirstLetter = function(input) {
    return input[0].toUpperCase() + input.slice(1);
};

// arrayObj is 2d array of strings
utils.convertToCsv = function (arrayObj, options) {
    options = options || {};
    let separator = options.separator || ',';

    // Temporary delimiter characters unlikely to be typed by keyboard
    // This is to avoid accidentally splitting the actual contents
    let tmpColDelim = String.fromCharCode(11); // vertical tab character
    let tmpRowDelim = String.fromCharCode(0); // null character

    let colDelim = '"' + separator + '"';
    let rowDelim = '"\r\n"';

    return '"' + arrayObj.map(function(row) {
        return row.map(function(cell) {
            return cell.replace(/"/g, '""'); // escape double quotes
        }).join(tmpColDelim);
    }).join(tmpRowDelim)
        .split(tmpRowDelim).join(rowDelim)
        .split(tmpColDelim).join(colDelim) + '"';
};

utils.csvBrowserDownload = function ($onClickEl, csvContent, fileName) {
    if (window.navigator.msSaveBlob) {
        // IE
        window.navigator.msSaveBlob(new Blob([csvContent]), fileName);
    } else if (window.webkitURL != null) {
        // chrone + firefox
        let href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        $onClickEl.attr('href', href);
        $onClickEl.attr('download', fileName);
    }
};

utils.convertWhitespaceFormat = function(text) {
    if (!text) {
        return '';
    }

    return text.replace(/-/g, '--').replace(/\s/g, '-');
};

utils.convertDashFormat = function(text) {
    if (!text) {
        return '';
    }
    
    return text.replace(/-/g, ' ').replace(/\s\s/g, '-');
};

utils.getBreakpoint = function() {
    if (typeof window.getComputedStyle !== 'undefined') {
        return window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
    }

    return '';
};

utils.getActiveCircle = function(e) {
    if (!$(e.target).closest('li').length) {
        return;
    }

    let $li = $(e.target).closest('li');

    if (!$(e.target).hasClass('j-active-circle')) {
        $('.folder-item .j-active-circle').remove();
    }

    if ($li.find('.folder-item .j-active-circle').length === 0) {
        if ($(e.target).closest('.folder-item').hasClass('active')) {
            $(e.target).closest('.folder-item').append('<span class="j-active-circle icon-yes"></span>');
        }
    }
};

utils.handleError = function(_e, err, $error) {
    let error,
        defaultErrorMessage = 'Something went wrong';

    try {
        let response = JSON.parse(err.responseText || '{}');
        error = response.message || response.msg || response.error || response.err || defaultErrorMessage;
    } catch (msg) {
        error = defaultErrorMessage;
    }

    if ($error) {
        $error.text(error).show();
    }
};

utils.stickyHeader = function(target) {
    let $target = $(target);

    if (!$target.attr('class')) {
        return;
    }

    let targetClass = $target.attr('class').split(' ')[0],
        appendHeader = ['<table class="' + targetClass + ' sticky-header">' +
            '<thead>' +
            '<tr class="th-inject"></tr>' +
            '</thead>'].join(''),
        $appendHeader = $(appendHeader),
        $targetContainer = $target.parent();

    $targetContainer.addClass('j-table-container');

    if ($target.attr('data-sticky') == 'yes') {
        return;
    }

    let dimensions = [];

    $('.j-table-container ' + target).each(function(_i, table) {
        if ($(table).parent().height() > $(table).height()) {
            return;
        }

        $(table).attr('data-sticky', 'yes');

        let copyHeader = $appendHeader.clone(),
            $table = $(table),
            cloneTh = $table.find('thead tr th').clone(),
            height = $table.find('thead').outerHeight() + 1;

        dimensions.push($.makeArray($table.find('th').map(function() { return $(this).outerWidth(); })));

        $table
            .css({ 'margin-top': -height })
            .find('thead').css({ 'visibility': 'hidden' });

        copyHeader
            .insertBefore($table.parent())
            .find('.th-inject').append(cloneTh);
    });

    $('.' + targetClass + '.sticky-header').each(function(i, header) {
        let $header = $(header),
            $th = $header.find('th'),
            $td = $header.siblings('.j-table-container').find(target + ' tbody td'),
            max;

        for (let j = 0; j < $th.length; j++) {
            max = Math.max($td.eq(j).outerWidth(), dimensions[i][j]);
            $th.eq(j).outerWidth(max);
        }
    });
};

utils.createModal = function($modal, $trigger, settings, initFunction) {
    let isInit = false;
    $modal.popup(settings);
    if ($trigger) {
        $trigger.click(function(e) {
            if (!isInit) {
                if (initFunction) {
                    initFunction();
                }
                isInit = true;
            }
            e.preventDefault();
            $modal.popup('show');
        });
    }
    
    let $xbutton = $modal.find('.icon-close');
    if ($xbutton.length) {
        $xbutton.click(function(e) {
            e.preventDefault();
            $modal.popup('hide');
        });
    }

    let $closeButton = $modal.find('.j-close-button');
    if ($closeButton.length) {
        $closeButton.click(function(e) {
            e.preventDefault();
            $modal.popup('hide');
        });
    }
};

utils.hideImage = function(element) {
    $(element).hide();
};

utils.checkPasswordTooltip = function($pass) {
    let $passwordTooltip = $('#passwordTooltip');
    let count = 0;

    if ($pass.val().length >= 8) {
        $passwordTooltip.find('.requirement-length span').addClass('icon-yes');
    } else {
        $passwordTooltip.find('.requirement-length span').removeClass('icon-yes');
    }

    if (new RegExp(/[a-z]+/).test($pass.val())) {
        $passwordTooltip.find('.requirement-lower span').addClass('icon-yes');
        count += 1;
    } else {
        $passwordTooltip.find('.requirement-lower span').removeClass('icon-yes');
    }

    if (new RegExp(/[A-Z]+/).test($pass.val())) {
        $passwordTooltip.find('.requirement-upper span').addClass('icon-yes');
        count += 1;
    } else {
        $passwordTooltip.find('.requirement-upper span').removeClass('icon-yes');
    }

    if (new RegExp(/\d+/).test($pass.val())) {
        $passwordTooltip.find('.requirement-numeric span').addClass('icon-yes');
        count += 1;
    } else {
        $passwordTooltip.find('.requirement-numeric span').removeClass('icon-yes');
    }

    if (new RegExp(/[^a-zA-Z0-9]+/).test($pass.val())) {
        $passwordTooltip.find('.requirement-special span').addClass('icon-yes');
        count += 1;
    } else {
        $passwordTooltip.find('.requirement-special span').removeClass('icon-yes');
    }

    if (count >= 3) {
        $passwordTooltip.find('.instructions span').addClass('icon-yes');
    } else {
        $passwordTooltip.find('.instructions span').removeClass('icon-yes');
    }
};

// detect svg support
utils.supportsSvg = function() {
    return typeof SVGRect !== 'undefined';
};

utils.removeURLParam = function(paramsToRemove) {
    let url = window.location.href.split('?')[0]+'?';
    let params = decodeURIComponent(window.location.search.substring(1));
    let paramsSeparated = params.split('&');
    
    for (let param of paramsSeparated) {
        let parameterName = param.split('=');
        if (!paramsToRemove.includes(parameterName[0])) {
            url = url + parameterName[0] + '=' + parameterName[1] + '&';
        }
    }
    return url.substring(0,url.length-1);
};

utils.checkScreenSize = function(operator, comparedToSize) {
    const screenSizeClasses = [
        'small phone',
        'phone',
        'tablet',
        'large tablet',
        'small desktop',
        'desktop',
        'large desktop'
    ];

    let breakpoint = utils.getBreakpoint();

    function DeviceOperatorException(message) {
        this.message = message;
        this.name = 'DeviceOperatorException';
    }

    if (breakpoint === '') {
        throw new DeviceOperatorException('No breakpoint data could be extracted.');
    }

    switch (operator) {
        case '>=':
            return screenSizeClasses.indexOf(breakpoint) >= screenSizeClasses.indexOf(comparedToSize) ;
        case '<=':
            return screenSizeClasses.indexOf(breakpoint) <= screenSizeClasses.indexOf(comparedToSize);
        case '>':
            return screenSizeClasses.indexOf(breakpoint) > screenSizeClasses.indexOf(comparedToSize);
        case '<':
            return screenSizeClasses.indexOf(breakpoint) < screenSizeClasses.indexOf(comparedToSize) ;
        case '=':
            return screenSizeClasses.indexOf(breakpoint) === screenSizeClasses.indexOf(comparedToSize) ;
        default:
            throw new DeviceOperatorException('Operation not supported.');
    }
};

export default utils;
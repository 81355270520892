import utils from './utils';

let urls = {};

/**
 * [valueToString description]
 * @param  {[type]} value [description]
 * @return {[type]}       [description]
 */
function valueToString(value) {

    let str;

    if (Array.isArray(value)) {
        str = [];

        value = value.filter(function(val) {
            return val !== '';
        });

        value = value.filter(utils.onlyUnique);

        value.forEach(function(part) {
            str.push(encodeURIComponent(part));
        });
        str = str.join(':');
    } else {
        str = encodeURIComponent(value);
    }

    return str;
}

/**
 * [list description]
 * @param  {[type]} list   [description]
 * @param  {[type]} params [description]
 * @return {[type]}        [description]
 */
urls.list = function (list, params) {

    let orgPart = '/u',
        url = '/list/' + utils.slugify(list.id + '-' + list.name),
        query = [];

    if (list.organization_id) {
        orgPart = '/org/' + list.organization_id + (list.organization_name ? ('-' + utils.slugify(list.organization_name)) : '');
    }

    Object.keys(params).forEach(function (key) {
        if (params[key]) {
            query.push(key + '=' + encodeURIComponent(params[key]));
        }
    });

    if (query.length) {
        url += '?' + query.join('&');
    }

    return orgPart + url;
};

/**
 * [listsearch description]
 * @param  {[type]} params [description]
 * @return {[type]}        [description]
 */
urls.listSearch = function (term, orgId, params) {

    let url = '';

    if (orgId) {
        url = '/org/' + orgId;
    }

    url += '/list/search?term=' + encodeURIComponent(term);

    Object.keys(params).forEach(function (key) {

        if (params[key]) {
            url += '&' + key + '=' + encodeURIComponent(params[key]);
        }
    });

    return url;
};

/**
 * [listexport description]
 * @param  {[type]} list   [description]
 * @param  {[type]} params [description]
 * @return {[type]}        [description]
 */
urls.listexport = function (list, params, type) {
    if ((typeof type === 'undefined') || (['xlsx', 'tsv', 'csv'].indexOf(type) === -1)) {
        type = 'xlsx';
    }

    let url = '/list/export/' + type + '/' + list.id,
        query = [];

    Object.keys(params).forEach(function (key) {
        if (params[key]) {
            query.push(key + '=' + encodeURIComponent(params[key]));
        }
    });

    if (query.length) {
        url += '?' + query.join('&');
    }

    return url;
};

/**
 * [dashboard description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
urls.dashboard = function (data) {

    let url = '/';

    if (data.organizationId) {

        url += 'org/' + data.organizationId + '/bomManagement';

        if (data.project) {
            url += '?project=' + data.project;
        }
    }

    return url;
};

urls.parametric = {};

/**
 * build parametric url
 * @param  {[type]} params [description]
 * @return {[type]}        [description]
 */
urls.parametric.build = function(params) {

    let query = [],
        url;

    if (!params || !params.path) {
        return params.path || '';
    }

    if (params.path) {
        url = params.path;
    }

    if (params.Class) {
        url += '/' + encodeURIComponent(utils.convertWhitespaceFormat(params.Class));
    }

    if (params.Category) {
        url += '/' + encodeURIComponent(utils.convertWhitespaceFormat(params.Category));
    }

    // append not configured parts to query
    Object.keys(params).forEach(function(key) {

        let value;

        if (params[key] && ['path', 'Class', 'Category'].indexOf(key) === -1) {

            value = valueToString(params[key]);

            if (value !== '') {
                query.push(encodeURIComponent(key) + '=' + value);
            }
        }

    }.bind(this));

    if (query.length) {
        url += '?' + query.join('&');
    }

    return url;
};

/**
 * build compare url
 * @param  {[type]} parts [description]
 * @return {[type]}       [description]
 */
urls.compare = function(parts) {

    if (!parts || parts.length < 2) {
        return 'javascript:void(0)';
    }

    return '/compare/' + parts.map(function(item) {

        let part = encodeURIComponent(item.part);

        if (item.manufacturer) {
            part += '---' + encodeURIComponent(item.manufacturer);
        }

        return part;
    }).join('--vs--');
};


export default urls;

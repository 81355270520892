/* global _gat,window.TRACKING,$ */
let tracking = {};
import global from '../modules/global';

tracking.recordUserClick = function(category, action, label, extra) {

    if (window.FC.bot === 'true') {
        return;
    }

    try {
        if (window.TRACKING.gaAccount) {
            gtag('event', action, {
                'event_category': category,
                'event_label': label,
                'value': (category === 'Outbound Links') ? '1' : '0',
                'send_to': window.TRACKING.gaAccount
            });
        }

        if (window.FC.site === 'findchips') {
            gtag('event', 'purchase', {
                'allow_custom_scripts': true,
                'value': '1',
                'transaction_id': action + extra.partNumber + label,
                'send_to': 'DC-6529245/buynow/flood0+transactions'
            });
        }
    } catch (err) { 
        // do nothing
    }

    if (window.TRACKING.ga4Account) {
        tracking.recordUserClickGA4(category, action, label, extra);
    }
};

tracking.recordUserClickGA4 = function(eventName, action, label, _extra) {
    if (window.FC.bot === 'true') {
        return;
    }

    let ga4Account = window.TRACKING.ga4Account ? window.TRACKING.ga4Account : window.TRACKING.gaAccount;

    try {
        gtag('event', eventName, {
            'event_action': action,
            'event_label': label,
            'send_to': ga4Account
        });
    } catch (err) {}
};

/**
 * [setUserTypeCookie description]
 */
tracking.setUserTypeCookie = function() {
    if (window.FC && window.FC.user) {
        let expires = new Date();
        expires.setTime(expires.getTime() + (1000 * 60 * 60 * 24 * 365));
        document.cookie = 'fc_user_type=buyer;expires=' + expires.toUTCString() + ';path=/';
    }
};

/**
 * This function should be used for outbound links
 * we want to keep functionality when users want to open link in new tab
 * @param  {[type]} event    [description]
 * @param  {[type]} link     [description]
 * @param  {[type]} category [description]
 * @param  {[type]} action   [description]
 * @param  {[type]} label    [description]
 * @return {[type]}          [description]
 */
tracking.recordUserClickOutbound = function(event, link, category, action, label) {

    if (window.FC.bot === 'true') {
        return;
    }

    try {

        gtag('event', action, {
            'event_category': category,
            'event_label': label,
            'value': (category === 'Outbound Links') ? '1' : '0',
            'send_to': window.TRACKING.gaAccount
        });

        if (typeof window.FC !== 'undefined') {

            if (['findchips'].indexOf(window.FC.site) !== -1) {

                gtag('event', 'purchase', {
                    'allow_custom_scripts': true,
                    'value': '1',
                    'transaction_id': action + window.FC.partNumber + label,
                    'send_to': 'DC-6529245/buynow/flood0+transactions'
                });
            }

            if (['findchips', 'oemstrade'].indexOf(window.FC.site) !== -1) {

                gtag('event', 'conversion', {
                    'send_to': window.FC.awData.tag + '/' + window.FC.awData.action,
                    'value': '1',
                    'currency': 'USD',
                    'transaction_id': action + window.FC.partNumber + label,
                    'event_callback': function() {
                        // do nothing
                    }
                });
            }
        }

    } catch (err) {
        // do nothing
    }

    event = event || window.event;

    if (event.ctrlKey || event.metaKey || !link.href || (link.target && link.target == '_blank')) {
        return;
    }

    if (event.preventDefault) {
        event.preventDefault();
    } else {
        event.returnValue = false;
    }

    setTimeout(function () {
        document.location.href = link.href;
    }, 500);
};

tracking.ckEventUrl = function(ckEventReq, callback) {
    if (window.FC.bot === 'true') {
        return;
    }

    let ck23f3543Timer = null;

    try {
        let ckImg = $(document.createElement('img'));
        ckImg.attr('src', ckEventReq);
        ckImg.appendTo('body');
        ckImg.addClass('hide');

        ck23f3543Timer = window.setTimeout(function () {
            if (callback && typeof callback === 'function') {
                callback();
            }
        }, 500);

        ckImg.onload = ckImg.onerror = function () {
            window.clearTimeout(ck23f3543Timer);
            if (callback && typeof callback === 'function') {
                callback();
            }
        };
    } catch (err) {

        window.clearTimeout(ck23f3543Timer);
        if (callback && typeof callback === 'function') {
            callback();
        }
    }
};

tracking.ckEvent = function(action, zone, value1, value2, value3, extra, callback) {

    if (window.FC.bot === 'true') {
        return;
    }

    let ck23f3543Timer = null;

    try {
        let ckData = {
            action: action,
            zone: zone
        };

        if (value1 != null) ckData['value1'] = value1;

        if (value2 != null) ckData['value2'] = value2;

        if (value3 != null) ckData['value3'] = value3;

        ckData['extra'] = 'p_ref=' + encodeURIComponent(window.location.href);
        if (extra != null) {
            ckData['extra'] += '|' + extra;
        }
        if (window.TRACKING.sessionId) ckData['extra'] += '|sessionId=' + encodeURIComponent(window.TRACKING.sessionId);
        if (window.TRACKING.sessionImpression) ckData['extra'] += '|r_ref=' + encodeURIComponent(window.TRACKING.sessionImpression);
        if (window.FC.h_crc) ckData['extra'] += '|h_crc=' + encodeURIComponent(window.FC.h_crc);

        if (window.TRACKING.userId != null) ckData['ui'] = window.TRACKING.userId;

        ckData['cb'] = Math.random();

        let ckEventReq = window.TRACKING.trackingImpression + '?' + $.param(ckData);

        let ckImg = $(document.createElement('img'));
        ckImg.attr('src', ckEventReq);
        ckImg.appendTo('body');
        ckImg.addClass('hide');

        ck23f3543Timer = window.setTimeout(function () {
            if (callback && typeof callback === 'function') {
                callback();
            }
        }, 500);

        ckImg.onload = ckImg.onerror = function () {
            window.clearTimeout(ck23f3543Timer);
            if (callback && typeof callback === 'function') {
                callback();
            }
        };
    } catch (err) {

        window.clearTimeout(ck23f3543Timer);
        if (callback && typeof callback === 'function') {
            callback();
        }
    }
};

/**
 * open, download or request cad model
 * @param  {[type]} category [description]
 * @param  {[type]} label    [description]
 * @param  {[type]} value    [description]
 * @return {[type]}          [description]
 */
tracking.recordCadModelEvent = function(category, label, value) {

    gtag('event', 'cadmodel', {
        'event_category': category,
        'event_label': label,
        'value': value,
        'send_to': window.TRACKING.gaAccount
    });   
};


tracking.init = function() {
    global();

    $('body').on('click', '.track', function (e) {

        let category = $(this).data('category'),
            gaaction = $(this).data('gaaction'),
            label = $(this).data('label'),
            url = $(this).data('url');

        if (window.FC.bot === 'true') {
            return;
        }

        if (url) {
            tracking.ckEventUrl(url);
        }

        if (window.TRACKING.ga4Account) {
            tracking.recordUserClickGA4(category, gaaction, label);
        }
        tracking.recordUserClickOutbound(e, this, category, gaaction, label);

    });
};

window.recordUserClick = tracking.recordUserClick;
window.ckEvent = tracking.ckEvent;
window.ckEventUrl = tracking.ckEventUrl;

export default tracking;

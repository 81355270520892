import { initLookup, initAutocomplete } from '../modules/lookup';

export default function() {
    let $header = $('.is-home-header');
    let $headerSearch = $header.find('.search-form');
    let $bigSearch = $('.intro-big-search');
    let $bigSearchInput = $('.intro-big-search input[type=text]');
    if ($('.intro-big-search-tabs').length) {

        $('.intro-big-search-tabs').tabs({
            onChange: function (el) {
                let $el = $(el);
                let tab = $el.data('tab');

                if (tab === 'parametric') {
                    initLookup($bigSearchInput);
                } else {
                    initAutocomplete($bigSearchInput, true);
                }

                $bigSearch.prop('action', $el.attr('data-action'));
                $bigSearchInput.prop('placeholder', $el.attr('data-placeholder'));
                $bigSearchInput.prop('name', $el.attr('data-name'));
                $bigSearchInput.trigger('focus');
                $('.intro-big-search-tabs .example .phrase').addClass('hide');
                $(`.intro-big-search-tabs .example .phrase[data-type=${tab}]`).removeClass('hide');

                switch (tab) {
                    case 'part': {
                        let $headerSearchTab = $headerSearch.find('.j-dropdown .hyperlink[data-action=search]');
                        $headerSearchTab.attr('data-js-click', true);
                        $headerSearchTab.trigger('click');
                        break;
                    }
                    case 'parametric': {
                        let $headerSearchTab = $headerSearch.find('.j-dropdown .hyperlink[data-action="parametric/search"]');
                        $headerSearchTab.attr('data-js-click', true);
                        $headerSearchTab.trigger('click');
                        break;
                    }
                    case 'intelligence': {
                        let $headerSearchTab = $headerSearch.find('.j-dropdown .hyperlink[data-action=detail]');
                        $headerSearchTab.attr('data-js-click', true);
                        $headerSearchTab.trigger('click');
                        break;
                    }
                }
            }
        });
    }

    $headerSearch.find('.j-dropdown .hyperlink').on('click', function() {
        let $this = $(this);
        if ($this.attr('data-js-click') == 'true') {
            return;
        }
        let action = $this.attr('data-action');
        $this.attr('data-js-click', 'false');
        switch (action) {
            case 'search':
                $('.intro-big-search-tabs span[data-tab=part]').trigger('click');
                break;
            case 'parametric/search':
                $('.intro-big-search-tabs span[data-tab=parametric]').trigger('click');
                break;
            case 'detail':
                $('.intro-big-search-tabs span[data-tab=intelligence]').trigger('click');
                break;
        }
    });
}
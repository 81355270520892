import global from './global';
import Bloodhound from '../../vendor/bloodhound.min.js';
import utils from '../lib/utils';
import urls from '../lib/urls';
import '../../../../shared/javascripts/vendor/typeahead.jquery.js';

export default function() {
    $(function() {
        global();

        $('#part').trigger('focus');

        window.FC.searchData = [];

        // autocomplete functionality
        $.ajax({
            url: '/sites/findchips/javascripts/autocomplete_part_numbers_compressed.js',
            mimeType: 'text/javascript',
            dataType: 'json',
            cache: true,
            beforeSend: function (jqXHR) {
                jqXHR.setRequestHeader('Cache-Control', 'public, max-age=604800');
            },
        }).done(function (data) {
            window.FC.searchData = data;
        }).fail(function(){
            console.log(arguments);
        });

        initAutocomplete($('#basic-search-part'), true);
        initAutocomplete($('.j-part-auto'), false);

        let $part = $('input#part');

        $part.each(function (_i, input) {
            let $input = $(input);
            if ($input.attr('name') === 'part') {
                initAutocomplete($input, true);
            } else {
                initLookup($input);
            }
        });

        let $parametricSearch = $('.is-parametric-search-page .intro-big-search-holder input[name=term]');
        if ($parametricSearch.length) {
            initLookup($parametricSearch);
        }

        setTimeout(function(){
            $part.focus();
        }, 0);

        $('.j-category-dropdown-open .hyperlink').on('click', function(e) {
            e.preventDefault();
        });

        // header search - switch search - distributor, inteligence, parametric
        $('#j-category-dropdown .hyperlink').on('click', function(e){
            e.preventDefault();

            let $this = $(this),
                action = $this.data('action'),
                $form = $this.closest('form'),
                $input = $form.find('input[type=text]'),
                $dropdown = $this.closest('.j-dropdown'),
                text = $this.text();

            $('.j-category-dropdown-open span').text(text);
            $form.attr('action', '/' + action);

            if (action === 'parametric/search') {
                $input.attr('name', 'term');
                initLookup($input);
                $this.closest('.search-form').addClass('is-parametric');
                $this.closest('.search-form').removeClass('compare-and-pi');
            } else {
                $input.attr('name', 'part');
                initAutocomplete($input, true);
            }

            if (action === 'detail') {
                $this.closest('.search-form').addClass('compare-and-pi');
                $this.closest('.search-form').removeClass('is-parametric');
            }

            if (action === 'search') {
                $this.closest('.search-form').removeClass('compare-and-pi');
                $this.closest('.search-form').removeClass('is-parametric');

            }

            $('#j-category-dropdown .hyperlink').removeClass('current');
            $this.addClass('current');

            $dropdown[0].closeTooltip();
            $input.trigger('focus');
        });

        // we don't print window.FC.user on chat
        if (typeof window.FC === 'undefined' || !window.FC.user) {
            window.FC.user = typeof window.__PRELOADED_STATE__ !== 'undefined' ? window.__PRELOADED_STATE__.chat.currentUser.id : false;
        }
    });
}

export function initLookup($input) {

    let engine = new Bloodhound({
        identify: function(o) { return o.id_str; },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('Class', 'Category'),
        dupDetector: function(a, b) { return a.id_str === b.id_str; },
        // prefetch: remoteHost + '/demo/prefetch',
        remote: {
            url: '/parametric/lookup?term=%QUERY',
            wildcard: '%QUERY'
        }
    });

    function engineWithDefaults(q, sync, async) {
        if (q === '') {
            sync([]);
        } else {
            engine.search(q, sync, async);
        }
    }

    $input.typeahead('destroy');

    $input.typeahead({
        hint: false,
        highlight: true,
        minLength: 3,
        limit: 10,
        display: false
    },{
        displayKey: function() {
            return $input.val();
        },
        source: engineWithDefaults,
        limit: 50,
        templates: {
            suggestion: function (item) {
                let Class = item.Class || '',
                    Category = (item.Category || '').split('||')[0],
                    categoryIcon = Class ? '<span title="' + Class + '" class="category-icon j-home-category-icon  icon-' + utils.toClassName(Class) + '"></span> ' : '';
                return '<div>' + categoryIcon + Class + (Category ? (' - ' + Category) : '') + '</div>';
            },
            empty: ''
        }
    });

    //add auto submit upon selection (either by click or enter)
    $input.bind('typeahead:selected', function(_e, option) {

        let params = {
            term: $input.val(),
            path: '/parametric'
        };

        Object.keys(option).forEach(function(key) {

            if (['relatives'].indexOf(key) !== -1) {
                return true;
            }

            let val = option[key];

            val = (utils.isNumber(val)) ? utils.numberToDecimal(val) : val;

            // for now just pick first category
            if(key === 'Category') {
                val = val.split('||')[0];
            }

            if(val.match(/\|\|/)) {
                val = val.split('||');
            }

            params[key] = val;
        });

        window.location.href = urls.parametric.build(params);
    });

    let $wrapper = $input.closest('.search-form');
    let isHidden = $wrapper.hasClass('hide');
    if (isHidden) {
        $wrapper.removeClass('hide');
    }
    let $ttMenu = $input.siblings('.tt-menu');
    let thisTtMenuWidth = $ttMenu.width();
    let thisSubmitWidth = $input.closest('.twitter-typeahead').siblings('input[type=submit]').outerWidth();
    $ttMenu.css('width', thisTtMenuWidth - thisSubmitWidth);
    if (isHidden) {
        $wrapper.addClass('hide');
    }
    $input.siblings('.tt-menu').css('width', thisTtMenuWidth - thisSubmitWidth);
}

export function initAutocomplete($input, initEvents) {

    if (typeof initEvents == 'undefined') {
        initEvents = false;
    }

    $input.typeahead('destroy');

    $input.typeahead({
        hint: false,
        highlight: true,
        minLength: 1,
        limit: 10,
        display: false
    },{
        displayKey: 'mpn',
        source: function(query, cb){
            let matches = [];
            window.FC.searchData.forEach(function(item){
                if(item.mpn.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    matches.push(item);
                }
            });
            matches = matches.slice(0, 50);
            cb(matches);
        },
        limit: 50,
        templates: {
            suggestion: function (item) {
                let Class = item['class'] || '',
                    categoryIcon = Class ? '<span title="' + Class + '" class="category-icon j-home-category-icon  icon-' + utils.toClassName(Class) + '"></span> ' : '';
                return '<div>' + categoryIcon + item.mpn + '</div>';
            }
        }
    });

    if (initEvents) {

        //add auto submit upon selection (either by click or enter)
        $input.bind('typeahead:selected', function(event, _part) {
            $(event.target).closest('form').trigger('submit');
        });


        $input.each(function (_i, el) {
            let $el = $(el);
            let $wrapper = $el.closest('.search-form');
            let isHidden = $wrapper.hasClass('hide');
            if (isHidden) {
                $wrapper.removeClass('hide');
            }
            let $ttMenu = $el.siblings('.tt-menu');
            let thisTtMenuWidth = $ttMenu.width();
            let thisSubmitWidth = $el.closest('.twitter-typeahead').siblings('input[type=submit]').outerWidth();
            $ttMenu.css('width', thisTtMenuWidth - thisSubmitWidth);
            if (isHidden) {
                $wrapper.addClass('hide');
            }
        });
    }
}
